import {analyticsApi, isTargetingLocalServer} from "../constants/apiRoutes";
import axios from "axios";
// import LogRocket from "logrocket";

export function identifyClientSide(authUser, userProfile) {

  if (window)  {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      user: {
        ...authUser,
        ...userProfile,
      }
    })
  } 
  // Logrocket only works client side
  // const {firstName, lastName} = userProfile;
  // LogRocket.identify(authUser.uid, {
  //   name: `${firstName} ${lastName}`,
  //   ...payload
  // });

  // Identify other tools (e.g. customer IO) server side
  // return axios.post(
  //   `${analyticsApi}/identify`,
  //   payload
  // );
}

export async function trackEvent(name, attributes) {
  // If in development, then post to analytics API directly for easy debugging
  if (isTargetingLocalServer) {
    const analyticsEventApi = `${analyticsApi}/track/${name}`
    console.log("process.env.GATSBY_API_TOKEN", process.env.GATSBY_API_TOKEN)
    return axios.post(analyticsEventApi, attributes, {
      'headers': {
        'Authorization': process.env.GATSBY_API_TOKEN,
      }
    });
  } 
  // Else (read: staging or production) check if window exists and push events to GTM server
  // The GTM server then forwards the event to the analytics API
  else if (window)  {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
        event: name,
        ...attributes
    })
  } 
}
