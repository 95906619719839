// React
import React from "react";
import PropTypes from "prop-types";

// Material ui
import {makeStyles} from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  root: ({topSpacing, bottomSpacing, paddingTop, paddingBottom, inheritHeight}) => ({
    width: "100%",
    display: "flex",
    alignItems: "center" /* horizontal */,
    justifyContent: "center" /* vertical */,
    paddingTop: topSpacing ? theme.spacing(topSpacing) : (paddingTop || 0),
    paddingBottom: bottomSpacing ? theme.spacing(bottomSpacing) : paddingBottom || 0,
    height: inheritHeight ? "100%" : "100vh",
  }),
  content: {
    width: 400,
    maxWidth: "90%",
    margin: "auto",
    textAlign: "center",
  },
  message: {
    marginTop: 20,
  },
}));

export default function LoadingIndicator(props) {
  const {message} = props;
  const classes = useStyles(props);

  return (
    <div
      id="loading-indicator"
      className={classes.root}
    >
      <div className={classes.content}>
        <CircularProgress
          className={classes.progressIndicator}
          size={30}
        />
        {message && (
          <Typography className={classes.message}>{message}</Typography>
        )}
      </div>
    </div>
  );
}

LoadingIndicator.propTypes = {
  message: PropTypes.string,
  inheritHeight: PropTypes.bool,
};
