import React from "react";
import MuiLink from "@material-ui/core/Link";
import {Link as GatsbyLink} from "gatsby";
import {makeStyles} from "@material-ui/core/styles";

function getLinkColor(color, theme) {
  if (color === "primary") {
    return theme.palette.primary.light;
  }
  if (color === "white") {
    return "white";
  }
  return "rgba(0, 0, 0, 0.75)";
}

function getLinkHoverColor(color, theme) {
  if (color === "primary") {
    return theme.palette.primary.dark;
  }
  if (color === "white") {
    return "white";
  }
  return "rgba(0, 0, 0, 0.9)";
}

const useLinkStyles = makeStyles((theme) => ({
  root: ({color, weight, hoverColor, gutterSpacingTop, gutterSpacingBottom, gutterSpacingLeft, gutterSpacingRight}) => ({
    paddingTop: theme.spacing(gutterSpacingTop || 0),
    paddingBottom: theme.spacing(gutterSpacingBottom || 0),
    paddingLeft: theme.spacing(gutterSpacingLeft || 0),
    paddingRight: theme.spacing(gutterSpacingRight || 0),
    margin: "auto",
    color: getLinkColor(color, theme),
    fontWeight: weight === "bold" ? 700 : 400,
    textDecoration: 'none',
    "&:hover": {
      color: getLinkHoverColor(color, theme),
    },
  }),
}));

const Link = React.forwardRef(function Link(props, ref) {
  const classes = useLinkStyles(props);

  return (
    <MuiLink
      component={GatsbyLink}
      ref={ref}
      className={classes.root}
      underline="none"
      children={props.children}
      to={props.to}
      onClick={props.onClick}
    />
  );
});

export default Link;

export function ExternalLink(props) {
  const {to, children} = props;
  const classes = useLinkStyles(props);
  return (
    <a
      className={classes.root}
      href={to}
      target="_blank"
      rel="noopener noreferrer"
    >
      {children}
    </a>
  );
}
