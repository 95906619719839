// Public routes
export const home = "/";
export const freelancers = "/freelancers/";
export const teams = "/teams/";
export const teamProfile = "/teams/profile/";
export const pricing = "/pricing/";
export const privacy = "/privacy/";
export const termsOfService = "/terms/";

// routes ONLY accessible to unauthenticated visitors
export const login = "/login/";
export const signup = "/signup/";

//
export const app = "/app/";
export const clientCreateBusiness = "/app/create-business/";
export const clientTeams = "/app/teams/";
export const clientTeamsTab = "/app/teams/:tab/";
export const clientTeamsContract = "/app/teams/contracts/:contractId/";
export const clientTeamsRequest = "/app/teams/requests/:requestId/";
export const clientBills = "/app/bills/";
export const clientBill = "/app/bills/:billId/";
export const clientSettings = "/app/settings/";
export const clientSettingsTab = "/app/settings/:tab/";

// routes only accessible to freelancers
// export const freelancer = "/app/freelancer/";
export const freelancerProfileSetup = "/app/freelancer/profile-setup/";
export const freelancerContracts = "/app/freelancer/contracts/";
export const freelancerContractsTab = "/app/freelancer/contracts/:tab/";
export const freelancerTeamContract = "/app/freelancer/contracts/team/:contractId/";
export const freelancerHireContract = "/app/freelancer/contracts/hire/:contractId/";
export const freelancerLeads = "/app/freelancer/leads/";
export const freelancerLeadsTab = "/app/freelancer/leads/:tab/";
export const freelancerRequest = "/app/freelancer/leads/requests/:requestId/";
export const freelancerInvite = "/app/freelancer/leads/invites/:inviteId/";
export const freelancerTeams = "/app/freelancer/teams/";
export const freelancerTeam = "/app/freelancer/teams/:teamId/";
export const freelancerTeamTab = "/app/freelancer/teams/:teamId/:tab/";
export const freelancerTeamMember = "/app/freelancer/teams/:teamId/members/:userId/";
export const freelancerTeamApplicant = "/app/freelancer/teams/:teamId/applicants/:userId/";
export const freelancerBills = "/app/freelancer/bills/";
export const freelancerBill = "/app/freelancer/bills/:billId/";
export const freelancerBillCreateNew = "/app/freelancer/bills/create/new/";
export const freelancerInvoices = "/app/freelancer/invoices/";
export const freelancerSettings = "/app/freelancer/settings/";
export const freelancerSettingsTab = "/app/freelancer/settings/:tab/";
export const freelancerSettingsPersonal = "/app/freelancer/settings/personal/";
export const freelancerSettingsExperience = "/app/freelancer/settings/experience/";
export const freelancerSettingsFinancial = "/app/freelancer/settings/financial/";

// routes only accessible to admins
export const adminUsers = "/app/admin/users/";
