// React
import React from "react";
import {navigate} from "@reach/router";

// Firebase app
import {AuthUserContext} from "./context";
import {FirebaseContext, useFetchFirestore} from "../Firebase";
import * as routes from "../../constants/routes";

// Other libs
import {identifyClientSide, trackEvent} from "../../services/analytics";

export const withAuthentication = (Component) => (props) => {
  const [authUser, setAuthUser] = React.useState({});
  // const [userProfile, setUserProfile] = React.useState(null);
  const [isAuthenticating, setIsAuthenticating] = React.useState(true);
  const firebase = React.useContext(FirebaseContext);
  
  React.useEffect(() => {
    if (firebase) {
      const unsubscribe = firebase.auth.onAuthStateChanged(
        (authUser) => {
          setAuthUser(
            authUser
              ? {
                uid: authUser.uid,
                email: authUser.email,
                // profilePhoto: authUser.profilePhoto,
                emailVerified: authUser.emailVerified,
                // providerData: authUser.providerData,
              }
              : null
          );
          setIsAuthenticating(false);
          if (authUser && authUser.uid) {
            const ts = firebase.createTimestamp();

            // This also fires the trigger_write_user cloud function
            // Which makes identify calls to other services (C.IO etc.)
            firebase.user(authUser.uid)
              .update({
                lastUpdatedAt: ts,
                lastSessionAt: ts,
              });
          }
        },
        () => {
          setIsAuthenticating(false);
          setAuthUser(null);
        }
      );
      return () => unsubscribe();
    }
  }, [firebase]);


  const userRef = (firebase && authUser) ? firebase.user(authUser.uid) : null;
  const [ userProfile, loadingUserProfile ] = useFetchFirestore(
    userRef,
    { listen: true }
  )

  React.useEffect(() => {
    if (userProfile) {
      identifyClientSide(authUser, userProfile)
    }
  }, [userProfile, authUser])

  const hasAuthenticated = Boolean(authUser && authUser.uid);
  const authProfile = {
    ...authUser,
    ...userProfile,
    profileHasLoaded: !loadingUserProfile,
    hasAuthenticated,
    isAuthenticating,
    logout: () => navigate(routes.home)
      .then(() => {
        trackEvent('logged_out', { email: authUser.email })
        return firebase.auth.signOut()
      })
  }

  return (
    <AuthUserContext.Provider value={authProfile}>
      <Component {...props} />
    </AuthUserContext.Provider>
  );
};
