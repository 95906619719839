import React from "react";

export const AuthUserContext = React.createContext(null);

export const withAuthUser = (Component) => (props) => (
  <AuthUserContext.Consumer>
    {(authUser) => <Component {...props} authUser={authUser}/>}
  </AuthUserContext.Consumer>
);

export const BusinessContext = React.createContext(null);

// const TrackingContext = React.createContext(null);
//
// const withTracker = Component => props => (
//   <TrackingContext.Consumer>
//     {tracker => <Component {...props} tracker={tracker} />}
//   </TrackingContext.Consumer>
// );
