import React from "react";
import {useLocation} from "@reach/router";
import {AuthUserContext, BusinessContext} from "./context";
import {FirebaseContext, useFetchFirestore} from "../Firebase";
import queryString from "query-string";

export const withBusinessContext = (WrappedComponent, options = {}) => (props) => {
  const location = useLocation();
  const parsedQueries = queryString.parse(location.search);

  const firebase = React.useContext(FirebaseContext);
  const authUser = React.useContext(AuthUserContext);

  const businessRef = authUser.uid && parsedQueries.business 
    ? firebase.business(parsedQueries.business)
    : null;
  const [ selectedBusiness, loadingSelectedBusiness ] = useFetchFirestore(
    businessRef,
    { listen: true }
  ) 

  const selectedBusinessId = selectedBusiness ? selectedBusiness.uid : null;
  React.useEffect(() => {
    if (selectedBusinessId) {
      firebase.user(authUser.uid)
        .update({
          lastUpdatedAt: firebase.createTimestamp(),
          lastVisitedBusiness: selectedBusinessId,
        });
    }
  }, [firebase, authUser.uid, selectedBusinessId])

  return (
    <BusinessContext.Provider value={{selectedBusiness, isFetching: loadingSelectedBusiness }}>
      <WrappedComponent {...props} />
    </BusinessContext.Provider>
  );
};
